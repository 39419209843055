import React from 'react'

import {withRails} from '../../contexts/RailsContext'
import withSWR from '../../contexts/SwrContext'

// Chakra imports
import {Flex, Grid, useColorModeValue} from '@chakra-ui/react'

// Custom components
import AdminLayout from '../../layouts/Admin'
import {VSeparator} from '../../components/separator/Separator'

import OrdersByMonth from '../KpiScreen/components/OrdersByTimeFrame'
import SalesByMonth from '../KpiScreen/components/SalesByTimeFrame'
import MonthlyEnrollmentsByType from './components/MonthlyEnrollmentsByType'
import CommissionsByMonth from './components/CommissionsByMonth'
import RecentEnrollments from './components/RecentEnrollments'
import RecentOrders from './components/RecentOrders'
import SignUpsByCustomerType from './components/SignUpsByCustomerType'
import MTDEnrollmentsVsPriorEnrollments from './components/MTDEnrollmentsVsPriorMTDEnrollments'
import MTDOrphanVsPriorOrphan from './components/MTDOrphanVsPriorOrphan'
import MTDTotalSalesVsPriorMTDTotalSales from './components/MTDTotalSalesVsPriorMTDTotalSales'
import EnrollmentsTrend from './components/EnrollmentsTrend'
import MTDQuantitySoldByItem from './components/MTDQuantitySoldByItem'
import TotalSalesTrend from './components/TotalSalesTrend'
import TotalEnrollments from './components/TotalEnrollments'
import CustomerChangeLog from './components/CustomerChangeLog'
import Aia from './components/AIA'
import {KpiContextProvider} from '../../contexts/KpiContext'
import Report from '../../components/reports/Report'
import {formatDate, oneYearAgo} from '../../utils/formatter'

export const DashboardScreen = () => {
  // Chakra Color Mode
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100')
  const formatedOneYearAgo = formatDate(oneYearAgo())
  const formatedToday = formatDate(new Date())

  return (
    <AdminLayout>
      <KpiContextProvider>
        <Flex
          direction={{base: 'column', xl: 'row'}}
          pt={{base: '130px', md: '80px', xl: '80px'}}
        >
          <Flex direction="column" width="stretch">
            <Report
              name={'Orders by Month'}
              title={'Orders by Month'}
              component={OrdersByMonth}
              defaultStartDate={formatedOneYearAgo}
              defaultEndDate={formatedToday}
            />
            <Grid
              gap="20px"
              templateColumns={{base: 'repeat(auto-fit, minmax(250px, 1fr))'}}
            >
              <Report
                name={'Sales by Month'}
                component={SalesByMonth}
                title="YTD Sales"
                defaultStartDate={formatedOneYearAgo}
                defaultEndDate={formatedToday}
              />
              <Report
                name={'Monthly Enrollments by Type'}
                component={MonthlyEnrollmentsByType}
              />
              <Report
                name={'Commissions by Month'}
                component={CommissionsByMonth}
              />
            </Grid>
            <Grid
              templateColumns={{base: 'repeat(auto-fit, minmax(250px, 1fr))'}}
              gap="20px"
              display={{base: 'block', lg: 'grid'}}
            >
              <Report
                name={'Recent Enrollments'}
                component={RecentEnrollments}
              />
              <Report name={'Recent Orders'} component={RecentOrders} />
            </Grid>
            <Report
              name={'Sign Ups by Customer Type'}
              title={'Number of Sign Ups by Customer Type Over Time'}
              component={SignUpsByCustomerType}
            />
            <Report
              name={'MTD Enrollments Vs Prior MTD Enrollments'}
              component={MTDEnrollmentsVsPriorEnrollments}
            />
            <Report
              name={'MTD Orphan Vs Prior MTD Orphan'}
              component={MTDOrphanVsPriorOrphan}
            />
            <Report
              name={'MTD Total Sales Vs Prior MTD Total Sales'}
              component={MTDTotalSalesVsPriorMTDTotalSales}
            />
            <Report name={'Enrollments Trend'} component={EnrollmentsTrend} />
            <Report
              name={'MTD Quantity Sold by Item'}
              component={MTDQuantitySoldByItem}
            />
            <Report name={'Total Sales Trend'} component={TotalSalesTrend} />
            <Report name={'Total Enrollments'} component={TotalEnrollments} />
            <Report name={'Customer Changelog'} component={CustomerChangeLog} />
            <Report name={'AIA'} component={Aia} />
          </Flex>

          <VSeparator
            mx="20px"
            bg={paleGray}
            display={{base: 'none', xl: 'flex'}}
          />
        </Flex>
      </KpiContextProvider>
    </AdminLayout>
  )
}

DashboardScreen.propTypes = {}

export default withRails(withSWR(DashboardScreen))
